import './App.css';
import "tailwindcss/tailwind.css"
import BHFashion_logo from './Images/BHFashion_logo.png'

function App() {
  return (
      <div className="App">
        <header className="flex flex-col App-header bg-white items-center justify-center min-h-screen">
          <img src={BHFashion_logo} className="App-logo mb-10" alt="logo" />
          <p className='text-5xl font-bold font-courier mb-10' >
            Welcome to BackHome Fashion!
          </p>
          <p>
            We are rebuilding our services and products to serve you better! Please check back soon.
          </p>
        
        </header>
      </div>
  );
}

export default App;
